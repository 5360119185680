import { useEffect, useState, useRef } from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/new/1/1.png";
import img2 from "../../../assets/img/new/1/2.png";
import imgMob from "../../../assets/img/new/1/mob.png";

import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const First = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);
  const monetRef3 = useRef(null);
  const monetRef4 = useRef(null);
  const monetRef5 = useRef(null);
  const monetRef6 = useRef(null);
  const monetRef7 = useRef(null);
  const leftImgRef = useRef(null);
  const leftImgRef1 = useRef(null);
  const imgsRef = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(imgsRef.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top top",
          bottom: "top bottom",
          scrub: 6,
        },
        x: 300,

        autoAlpha: 0.5,
      });

      gsap.timeline().to(monetRef1.current, {
        x: 0,
        duration: 1,
        delay: 0.3,
      });
      gsap.timeline().to(monetRef2.current, {
        x: 0,
        duration: 1,
        delay: 0.3,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container" ref={col1}>
          <div className={[classes.firstWrapper, ""].join(" ")}>
            <div className={[classes.firstInfo, ""].join(" ")}>
              <h1 className={[classes.firstInfoTitle, "font-28"].join(" ")}>
                <span>Work Up Club</span>- The Ultimate Platform for Marketers
                and Advertisers, Who are in Search of Successful Promotion
              </h1>

              <div className={[classes.firstInfoBtn, ""].join(" ")}>
                <Button>Join Now!</Button>
              </div>
            </div>
            <div className={[classes.firstImgs, ""].join(" ")} ref={imgsRef}>
              <div
                className={[classes.firstImgsImg1, ""].join(" ")}
                ref={monetRef1}
              >
                <img src={img1} alt="you boost your online presence?" />
              </div>
              <div
                className={[classes.firstImgsImg2, ""].join(" ")}
                ref={monetRef2}
              >
                <img src={img2} alt="you boost your online presence?" />
              </div>
            </div>
          </div>
        </div>
        <div className={[classes.firstMob, ""].join(" ")}>
          <img src={imgMob} alt="" />
        </div>
      </div>
    </div>
  );
};

export default First;
