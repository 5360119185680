import { useEffect, useState, useRef } from "react";
import classes from "./Four.module.scss";
import img1 from "../../../assets/img/new/4/1.png";
import img2 from "../../../assets/img/new/4/2.png";
import img3 from "../../../assets/img/new/4/3.png";
import imgMob from "../../../assets/img/new/4/mob.png";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../../GlobalComponents/Button/Button";
import SwiperCore, { EffectFlip, Navigation, Pagination } from "swiper";
import "swiper/swiper.min.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Four = () => {
  const [my_swiper, set_my_swiper] = useState({});
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const myRef = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);
  const [rotateImg, setRotateImg] = useState(0);
  const [activeI, setActiveI] = useState(0);
  const array = [
    {
      id: 1,
      title: `1: Completion \n of surveys.`,
      text: "With our platform, you can create a survey and have performers complete it to get valuable insights and data about your target audience.",
    },
    {
      id: 2,
      title: "2: Social\n media activity",
      text: "It can help you get new clients and increase your business account's feedback. Performers boost your social media pages with likes and comments according to the amount you need.",
    },
    {
      id: 3,
      title: "3: Testing\n products",
      text: "Our performers can help you with product testing, including A/B testing, to professionally estimate and test your new product.",
    },
    {
      id: 4,
      title: "4: Data\n entry",
      text: "If you need real people registered on your platform, we provide data entry services where our performers can complete tasks and register for your platform.",
    },
    {
      id: 5,
      title: "5: Video\n Watching",
      text: "We also offer video watching by our performers that can help you get a high number of views for your video content.",
    },
    {
      id: 6,
      title: "6: Email\n marketing",
      text: "If you need help with email correspondence or activating your business email, we offer those services as well.",
    },
  ];

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.four, "mb100"].join(" ")} ref={col1}>
      <div className={[classes.fourBody, ""].join(" ")}>
        <p
          className={[classes.fourRowRightAbout, ""].join(" ")}
          ref={monetRef1}
        >
          Cases
        </p>
        <div className="container">
          <h2 className={[classes.fourTitle, "font-28"].join(" ")}>
            <span>Cases:</span> Benefits for Marketers and Advertisers
          </h2>
          <p className={[classes.fourSubtitle, "font-16"].join(" ")}>
            At Work Up Club, we offer a wide range of services that cater to the
            needs of marketers and advertisers.
          </p>
          <div className={[classes.fourWrapper, ""].join(" ")}>
            <div className={[classes.fourRow, ""].join(" ")}>
              <div className={[classes.fourRowLeft, ""].join(" ")}>
                <div className={[classes.fourRowLeftImg, ""].join(" ")}>
                  <img src={width > 1220 ? img1 : imgMob} alt="Work Up Club" />
                </div>
                <div className={[classes.fourRowLeftSlider, ""].join(" ")}>
                  <Swiper
                    modules={[Navigation, Pagination]}
                    pagination={{ clickable: true, el: myRef.current }}
                    spaceBetween={0}
                    slidesPerView={1}
                    allowTouchMove={false}
                    onSlideChange={(swiperCore) => {
                      const {
                        activeIndex,
                        snapIndex,
                        previousIndex,
                        realIndex,
                      } = swiperCore;

                      setActiveI(realIndex);
                    }}
                    loop={true}
                    onInit={(ev) => {
                      set_my_swiper(ev);
                    }}
                  >
                    {array.map((item) => {
                      return (
                        <SwiperSlide key={item.id}>
                          <div className={[classes.slide, ""].join(" ")}>
                            <h3 className={[classes.slideTitle, ""].join(" ")}>
                              {item.title.split("\n").map((subS) => {
                                return <span>{subS}</span>;
                              })}
                            </h3>
                            <p
                              className={[
                                classes.slideSubTitle,
                                "font-16",
                              ].join(" ")}
                            >
                              {item.text}
                            </p>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
              <div className={[classes.fourRowRight, ""].join(" ")}>
                <div className={[classes.fourRowRightImg, ""].join(" ")}>
                  <img src={img2} alt="Work Up Club" />
                </div>
                <div
                  className={[classes.fourRowRightNext, ""].join(" ")}
                  onClick={() => {
                    setRotateImg(rotateImg + 60);
                    my_swiper.slideNext();
                  }}
                >
                  <div className={[classes.fourRowRightNextImg, ""].join(" ")}>
                    <img
                      src={img3}
                      alt=""
                      STYLE={`transform: rotate(${rotateImg}deg)`}
                    />
                    <p
                      className={[classes.fourRowRightNextImgAbs, ""].join(" ")}
                    >
                      {activeI + 1}
                    </p>
                  </div>
                  <div
                    className={[classes.fourRowRightNextLine, ""].join(" ")}
                  ></div>
                  <p className={[classes.fourRowRightNextMax, ""].join(" ")}>
                    6
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
