import { useEffect, useState, useRef } from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/new/2/1.gif";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import Button from "../../GlobalComponents/Button/Button";
import Marquee from "react-double-marquee";
gsap.registerPlugin(ScrollTrigger);
const Second = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className="mb100">
      <div className={[classes.second, "", ""].join(" ")} ref={col1}>
        <div className={[classes.secondBody, ""].join(" ")}>
          <div className="container">
            <div className={[classes.secondImg, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>

            <div className={[classes.secondWrap, ""].join(" ")}>
              <p className={[classes.secondText, "font-18"].join(" ")}>
                Are you struggling to get the traffic, engagement, and
                conversions on the Internet?
              </p>
              <p className={[classes.secondText, "font-18"].join(" ")}>
                Work Up Club is a leading online platform that connects
                marketers and advertisers with performers, who are seeking to
                complete tasks for your traffic increase.
              </p>
              <p className={[classes.secondText, "font-18"].join(" ")}>
                Our platform offers a unique opportunity for marketers and
                advertisers to earn high conversions by collaborating with
                performers to create and execute marketing campaigns that drive
                results.
              </p>
              <div className={[classes.secondBtn, ""].join(" ")}>
                <Button>Join Now!</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class={[classes.text, ""].join(" ")}
        style={{
          width: "100%",
          whiteSpace: "nowrap",
        }}
      >
        <Marquee delay={1000} class={[classes.text, ""].join(" ")}>
          <p> Maximize your promotion with Work Up Club!</p>
          <p> Maximize your promotion with Work Up Club!</p>
          <p> Maximize your promotion with Work Up Club!</p>
        </Marquee>
      </div>
    </div>
  );
};

export default Second;
