import React from "react";
import { StackedCarouselSlideProps } from "react-stacked-center-carousel";
import img1 from "../../../assets/img/new/7/1.svg"
import "./styles.scss";
import classes from "./Slider.module.scss"

export const Slide = React.memo(function (StackedCarouselSlideProps) {
    const {
        data,
        dataIndex,
        isCenterSlide,
        swipeTo,
        slideIndex
    } = StackedCarouselSlideProps;

    const coverImage = data[dataIndex].image;
    const text = data[dataIndex].text;
    const name = data[dataIndex].name;
    const subname = data[dataIndex].subname;
    const star = data[dataIndex].star;
    const colorStars = data[dataIndex].colorStars;

    console.log(coverImage);
    return (
        <div className="card-card" draggable={false}>
            <div className={`cover fill ${isCenterSlide ? "off" : "on"}`}>
                <div
                    className="card-overlay fill"
                    onClick={() => {
                        if (!isCenterSlide) swipeTo(slideIndex);
                    }}
                />
            </div>
            <div style={{ width: '100%' }} className="detail fill">
                <div className="discription">
                    <div className={[classes.slide, ""].join(" ")}>
                        <div className={[classes.slideTop, ""].join(" ")}>
                            <div className={[classes.slideTopLeft, ""].join(" ")}>
                                <img src={coverImage} alt={name} />
                            </div>



                        </div>
                        <div className={[classes.slideStars, ""].join(" ")}>
                            <img src={img1} alt="" />
                        </div>

                        <p className={[classes.slideText, "font-14"].join(" ")}>
                            {text}
                        </p>


                    </div>

                </div>
            </div>
        </div>
    );
});

export default Slide;