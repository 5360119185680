import React, { useEffect } from "react";
import img1 from "../../../assets/img/HomePage/slider/1.png";
import img2 from "../../../assets/img/HomePage/slider/2.png";
import img3 from "../../../assets/img/HomePage/slider/3.png";
import img4 from "../../../assets/img/HomePage/slider/4.png";
import classes from "./Slider.module.scss";
import imgLeft from "../../../assets/img/HomePage/slider/left.png";
import imgRight from "../../../assets/img/HomePage/slider/right.png";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
// import { Fab } from "@material-ui/core";
// import testlefticon from "../assets/pictures/testlefticon.svg"
// import testrightiicon from "../assets/pictures/testrightiicon.svg"

import "./styles.scss";
import { Slide } from "./testimonial.js";
// import pic1 from "../assets/pictures/our-clients/pic1.png"
// import pic2 from "../assets/pictures/our-clients/pic2.png"
// import pic3 from "../assets/pictures/our-clients/pic3.png"
// import pic4 from "../assets/pictures/our-clients/pic4.png"

const data = [
  {
    image: img1,
    text: '"I used Work Up Club for my social media activity needs and was blown away by the quality of work delivered by the performers. They helped me increase followers and engagement, which translated to more leads and sales for a business I advertise. I highly recommend Work Up Club to any marketer or advertiser looking to grow their social media presence." - Samantha B.',
    name: "Mark",
    star: 90,
    subname: "online - business marketer",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img2,
    text: '"The data entry service on Work Up Club is exceptional. I needed real people registered on my platform, and the performers delivered exactly what I needed. The process was simple, and I received the completed task on time. I will definitely use Work Up Club again for my data entry needs." - John D.',
    name: "Kate",
    star: 95,
    subname: "website’s advertiser",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img3,
    text: '"I had a new product that needed to be tested, and I used the A/B testing service on Work Up Club. The performers did an excellent job of professionally estimating and testing the product. They provided valuable feedback that helped me improve my product before launch. I highly recommend this service to any advertiser or marketer with a new product." - David S.',
    name: "Sam",
    star: 85,
    subname: "business marketer",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img4,
    text: '"I used Work Up Club for my internet research needs, and I was impressed by the thoroughness and accuracy of the research. The performers organized and analyzed the data on the specific theme I requested, providing me with valuable insights for my marketing campaign. I will definitely use this service again for future campaigns." - Sarah M.',
    name: "Sam",
    star: 85,
    subname: "business marketer",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
];

const Testimonial = () => {
  const ref = React.useRef(StackedCarousel);
  useEffect(() => {
    // setInterval(stuff, 6000);
    // console.log("i fire once");
  }, []);

  function stuff() {
    ref.current?.goNext();
  }

  return (
    <div className={[classes.slider, ""].join(" ")}>
      <h3 className={[classes.title, "font-28"].join(" ")}>
        <span>Reviews</span>
        {`\r`}
        from our Satisfied Clients
      </h3>

      <div className="card card-carrier">
        <div style={{ position: "relative" }}>
          <ResponsiveContainer
            carouselRef={ref}
            render={(width, carouselRef) => {
              let currentVisibleSlide = 3;
              if (width <= 1280) currentVisibleSlide = 3;
              if (width <= 720) currentVisibleSlide = 1;
              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={Slide}
                  slideWidth={width > 1650 ? 1074 : width > 1220 ? 750 : 500}
                  carouselWidth={width}
                  data={data}
                  maxVisibleSlide={3}
                  customScales={[
                    1,
                    width > 1220 ? 0.55 : 0.65,
                    width > 1900
                      ? -0.19
                      : width > 1850
                      ? -0.21
                      : width > 1800
                      ? -0.23
                      : width > 1750
                      ? -0.25
                      : width > 1700
                      ? -0.27
                      : width > 1650
                      ? -0.29
                      : width > 1600
                      ? -0.02
                      : width > 1550
                      ? -0.05
                      : width > 1500
                      ? -0.07
                      : width > 1450
                      ? -0.1
                      : width > 1400
                      ? -0.13
                      : width > 1350
                      ? -0.17
                      : width > 1300
                      ? -0.2
                      : width > 1250
                      ? -0.23
                      : width > 1220
                      ? -0.26
                      : width > 1150
                      ? 0.7
                      : width > 1100
                      ? 0.5
                      : width > 1050
                      ? 0.4
                      : width > 1000
                      ? 0.4
                      : width > 950
                      ? 0.4
                      : width > 900
                      ? 0.2
                      : 0.2,
                  ]}
                  transitionTime={450}
                  currentVisibleSlide={currentVisibleSlide}
                />
              );
            }}
          />
          <div className="">
            {" "}
            <div className={[classes.arrows, ""].join(" ")}>
              <div
                className={[classes.arrowsLeft, ""].join(" ")}
                onClick={() => ref.current?.goBack()}
              >
                <img src={imgLeft} alt="" />
              </div>
              <div
                className={[classes.arrowsRight, ""].join(" ")}
                onClick={() => ref.current?.goNext()}
              >
                <img src={imgRight} alt="" />
              </div>
            </div>
          </div>

          {/* <div
          className="card-button testimonial-left-button"
          size="small"
          onClick={() => ref.current?.goBack()}
        >
          left
        </div>
        <div
          className="card-button testimonial-right-button"
          size="small"
          onClick={() => ref.current?.goNext()}
        >
          right
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
