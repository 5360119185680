import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/img/logo.png";
import Button from "../../GlobalComponents/Button/Button";
const Header = () => {
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { pathname } = useLocation();
  const headerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${burgerOpened && classes.headerActive} ${
        classes.hideBg
      } no-select`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <a
            href="https://workupclub.com"
            target="_blank"
            className={[classes.headerRowLogo, ""].join(" ")}
          >
            <img src={logo} alt="" />
          </a>
          {/* <div className={[classes.headerRowBtn, ""].join(" ")}>
            <Button>Join Now!</Button>
          </div> */}

          {/* <div className={classes.headerRowLinks}>
            {headerRoutes.map((route) => (
              <Link
                key={route.id}
                to={`/${lang}/${route.path}`}
                className={`${route.path === _.last(pathname.split('/')) && classes.linkActive}`}
              >
                {t(route.text)}
              </Link>
            ))}
          </div>
          <div className={classes.headerRowRight}>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => setLocale(e.value)}
                value={lang}
                placeholder="EN"
              />
            </div>
            <a href="#" className={classes.login}>
              Log in
            </a>
            <div
              onClick={() => setBurgerOpened((value) => !value)}
              className={`${classes.burger} ${burgerOpened && classes.burgerActive}`}
            ></div>
          </div> */}
        </div>
      </div>
      {/* <div
        className={`${classes.mobileMenu} ${
          burgerOpened && classes.mobileMenuActive
        }`}
      >
        <div className="container">
          {headerRoutes.map((route) => (
            <Link
              key={route.id}
              to={`/${lang}/${route.path}`}
              className={`${
                route.path === _.last(pathname.split("/")) && classes.linkActive
              } ${classes.aLink}`}
              onClick={() => setBurgerOpened(false)}
            >
              {t(route.text)}
            </Link>
          ))}
          <div className={classes.btn}>
            <Button login>Log in</Button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
