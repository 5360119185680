import { useEffect, useState, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./components/GlobalComponents/Router/AppRouter";
import './App.scss';
import { images } from "./utils/imagesToLoad";
import Loader from "./components/GlobalComponents/Loader/Loader";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };
  useEffect(() => {
    if (isLoading) {
      document.querySelector("body").classList.add("ovf-hidden")
      // $('body').addClass("ovf-hidden");
    }
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => console.log("Failed to load images", err));
  }, []);
  useEffect(() => {
    if (!isLoading) {
      document.querySelector("body").classList.remove("ovf-hidden")
      // $('body').removeClass("ovf-hidden");
    }
  }, [isLoading]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [width, setWidth] = useState();
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div>
      <Loader isLoading={isLoading} />
      {!isLoading && <BrowserRouter>

        <Router />
        {/* <Footer /> */}
      </BrowserRouter>}


    </div>
  );
}

export default App;
