import { useEffect, useState, useRef } from "react";
import classes from "./Six.module.scss";

import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Six = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.second, "mb100"].join(" ")} ref={col1}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.secondTitle, "font-28"].join(" ")}>
            <span>Our</span>
            Values
          </h2>

          <div className={[classes.secondRow, ""].join(" ")}>
            <ul className={[classes.secondRowLeft, ""].join(" ")}>
              <li className={[classes.secondRowLeftItem, "font-16"].join(" ")}>
                Collaboration - We work closely with our clients to understand
                their goals.
              </li>
              <li className={[classes.secondRowLeftItem, "font-16"].join(" ")}>
                Excellence - We're passionate about what we do, and we're
                committed to delivering exceptional service and exceptional
                results to every client.
              </li>
            </ul>
            <ul className={[classes.secondRowRight, ""].join(" ")}>
              <li className={[classes.secondRowRightItem, "font-16"].join(" ")}>
                Integrity - we're honest, transparent, and ethical in all of our
                dealings, and we always put the needs of our clients first.
              </li>
              <li className={[classes.secondRowRightItem, "font-16"].join(" ")}>
                Growth - We're dedicated to helping our clients achieve their
                goals and reach their full potential.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Six;
