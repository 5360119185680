import { useEffect, useState, useRef } from "react";
import React from "react";
import classes from "./Five.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import img1 from "../../../assets/img/new/5/1.png";
gsap.registerPlugin(ScrollTrigger);
const Five = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.three, "mb100"].join(" ")} ref={col1}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.threeRow, ""].join(" ")}>
            <div className={[classes.threeRowLeft, ""].join(" ")}>
              <p className={[classes.threeRowLeftText, "font-18"].join(" ")}>
                At Work Up Club, we are constantly adding new services to cater
                to the needs of our clients.
              </p>
              <p className={[classes.threeRowLeftText, "font-18"].join(" ")}>
                Our platform is designed to be user-friendly, secure, and
                efficient, allowing you to focus on delivering high-quality
                work.
              </p>
              <p className={[classes.threeRowLeftText, "font-18"].join(" ")}>
                With our wide range of services, you can find the right solution
                to help you achieve your marketing and advertising goals.
              </p>
              <div className={[classes.threeRowLeftBtn, ""].join(" ")}>
                <Button>Join Now!</Button>
              </div>
            </div>
            <div className={[classes.threeRowRight, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
