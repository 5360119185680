import React from "react";
import classes from "./OverSeven.module.scss";
import img1 from "../../../assets/img/new/6/1.svg";
import img2 from "../../../assets/img/new/6/2.svg";
import img3 from "../../../assets/img/new/6/3.svg";
const OverSeven = () => {
  return (
    <div className={[classes.over, "mb100"].join(" ")}>
      <div className={[classes.overBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.overTitle, "font-28"].join(" ")}>
            Instruments for marketers and advertisers  on Work Up Club
          </h2>
          <div className={[classes.overRow, ""].join(" ")}>
            <div className={[classes.overRowColumn, ""].join(" ")}>
              <div className={[classes.overRowColumnImg, ""].join(" ")}>
                <img src={img1} alt="" />
              </div>
              <p className={[classes.overRowColumnText, "font-16"].join(" ")}>
                Over 50 countries to choose the geo of your audience are
                available.
              </p>
            </div>
            <div className={[classes.overRowColumn, ""].join(" ")}>
              <div className={[classes.overRowColumnImg, ""].join(" ")}>
                <img src={img2} alt="" />
              </div>
              <p className={[classes.overRowColumnText, "font-16"].join(" ")}>
                Possibility to set any campaign and have it done in the short
                term.
              </p>
            </div>
            <div className={[classes.overRowColumn, ""].join(" ")}>
              <div className={[classes.overRowColumnImg, ""].join(" ")}>
                <img src={img3} alt="" />
              </div>
              <p className={[classes.overRowColumnText, "font-16"].join(" ")}>
                Wide range of performers to choose from depending on his/her
                rating. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverSeven;
