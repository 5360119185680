import logo from '../assets/img/logo.png';
// import bg from '../assets/img/page_bg.webp';
// import img1 from '../assets/img/new/1/1.png';
// import img2 from '../assets/img/new/1/2.png';
// import img3 from '../assets/img/new/1/3.png';
// import img4 from '../assets/img/new/1/4.png';
// import img5 from '../assets/img/new/1/mob_1.webp';

export const images = [
    logo,
    // bg,
    // img1, img2,
    // img3, img4,
    //  img5,

];
