import { useEffect, useState, useRef } from "react";
import classes from "./Three.module.scss";

import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import img1 from "../../../assets/img/new/3/1.png";
gsap.registerPlugin(ScrollTrigger);
const Three = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.second, "mb100", ""].join(" ")} ref={col1}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondBg, ""].join(" ")}>
            <img src={img1} alt="Work Up Club" />
          </div>

          <h2 className={[classes.secondRowLeftTitle, "font-28"].join(" ")}>
            Work Up Club is <span>About…</span>
          </h2>
          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowLeft, ""].join(" ")}>
              <p className={[classes.secondRowLeftText, "font-18"].join(" ")}>
                At Work Up Club, we understand the value of your time and
                skills, which is why we offer a hassle-free platform that
                streamlines the process of finding and completing marketing
                projects. Our platform offers a variety of project categories,
                including social media management, influencer marketing, email
                marketing, and more, giving you the flexibility to work on
                projects that align with your interests and expertise.
              </p>
              <p className={[classes.secondRowLeftText, "font-18"].join(" ")}>
                Our platform ensures that marketers and advertisers get their
                campaigns done in a short period of time and for an attractive
                price . Once a project is completed, performers will release
                proofs of a task done.
              </p>
            </div>
            <div className={[classes.secondRowRight, ""].join(" ")}>
              <p className={[classes.secondRowRightText, "font-18"].join(" ")}>
                Join Work Up Club today and turn your marketing and advertising
                skills to the next level!
              </p>
              <p className={[classes.secondRowRightText, "font-18"].join(" ")}>
                With our user-friendly platform, a wide range of project
                categories, and a secure payment system, you can focus on
                getting quality work and growing your reputation as a trusted
                marketing professional.
              </p>
              <div
                className={[classes.secondRowRightAbout, ""].join(" ")}
                ref={monetRef1}
              >
                about…
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
