import { useEffect, useState, useRef } from "react";
import classes from "./Seven.module.scss";

import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Seven = () => {
  gsap.config({
    force3D: true,
  });
  const [checks, setChecks] = useState([
    {
      id: 1,
      text: "reviews written",
      active: false,
    },
    {
      id: 2,
      text: "surveys conducted",
      active: false,
    },
    {
      id: 3,
      text: "product tested",
      active: false,
    },
    {
      id: 4,
      text: "game tested",
      active: false,
    },
    {
      id: 5,
      text: "likes boosted",
      active: false,
    },
    {
      id: 6,
      text: "app downloaded",
      active: false,
    },
    {
      id: 7,
      text: "data entry",
      active: false,
    },
    {
      id: 8,
      text: "audio transcription",
      active: false,
    },
    {
      id: 9,
      text: "videos watched",
      active: false,
    },
    {
      id: 10,
      text: "email correspondence",
      active: false,
    },
    {
      id: 11,
      text: "other",
      active: false,
    },
  ]);
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  function checkArray(id, text) {
    setChecks(
      checks.map((item) => {
        if (item.id === id) {
          return { ...item, active: !item.active };
        } else {
          return { ...item };
        }
      })
    );
  }

  return (
    <div className={[classes.three, "mb100"].join(" ")} ref={col1}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.threeTitle, "font-28"].join(" ")}>
            Make a step to successful promotion with Work Up Club!
          </h2>
          <p className={[classes.threeSubtitle, "font-16"].join(" ")}>
            Complete your registration in 2 minutes right now.
          </p>

          <div className={[classes.threeRow, ""].join(" ")}>
            <div className={[classes.threeRowRight, ""].join(" ")}>
              <div className={[classes.threeRowRightForm, ""].join(" ")}>
                <div className={[classes.threeRowRightFormRow, ""].join(" ")}>
                  <div
                    className={[classes.threeRowRightFormRowRight, ""].join(
                      " "
                    )}
                  >
                    <input
                      type="text"
                      className="font-16"
                      placeholder="Your Name..."
                    />
                  </div>
                </div>
                <div className={[classes.threeRowRightFormRow, ""].join(" ")}>
                  <div
                    className={[classes.threeRowRightFormRowRight, ""].join(
                      " "
                    )}
                  >
                    <input
                      type="text"
                      className="font-16"
                      placeholder="Your E-Mail..."
                    />
                  </div>
                </div>
                <div className={[classes.threeRowRightFormRow, ""].join(" ")}>
                  <div
                    className={[classes.threeRowRightFormRowRight, ""].join(
                      " "
                    )}
                  >
                    <input
                      type="text"
                      className="font-16"
                      placeholder="What is the niche, where you seek promotion?..."
                    />
                  </div>
                </div>
                <div className={[classes.threeRowRightFormRow, ""].join(" ")}>
                  <div
                    className={[classes.threeRowRightFormRowRight, ""].join(
                      " "
                    )}
                  >
                    <input
                      type="text"
                      className="font-16"
                      placeholder="What GEO of performers are preferable for you?"
                    />
                  </div>
                </div>
              </div>
              <div className={[classes.threeRowRightBottom, ""].join(" ")}>
                <div
                  className={[classes.threeRowRightBottomCheckboxes, ""].join(
                    " "
                  )}
                >
                  <div
                    className={
                      checks[0].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(1, "reviews written");
                    }}
                  >
                    reviews written
                  </div>
                  <div
                    className={
                      checks[1].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(2, "surveys conducted");
                    }}
                  >
                    surveys conducted
                  </div>
                  <div
                    className={
                      checks[2].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(3, " product tested");
                    }}
                  >
                    product tested
                  </div>
                  <div
                    className={
                      checks[3].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(4, "game tested");
                    }}
                  >
                    game tested
                  </div>
                  <div
                    className={
                      checks[4].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(5, "likes boosted");
                    }}
                  >
                    likes boosted
                  </div>
                  <div
                    className={
                      checks[5].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(6, "app downloaded");
                    }}
                  >
                    app downloaded
                  </div>
                </div>
                <div
                  className={[classes.threeRowRightBottomCheckboxes, ""].join(
                    " "
                  )}
                >
                  <div
                    className={
                      checks[6].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(7, "data entry");
                    }}
                  >
                    data entry
                  </div>
                  <div
                    className={
                      checks[7].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(8, " audio transcription");
                    }}
                  >
                    audio transcription
                  </div>
                  <div
                    className={
                      checks[8].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(9, "videos watched");
                    }}
                  >
                    videos watched
                  </div>
                  <div
                    className={
                      checks[9].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(10, "email correspondence");
                    }}
                  >
                    email correspondence
                  </div>
                  <div
                    className={
                      checks[10].active
                        ? [
                            classes.threeRowRightBottomCheckboxesItem,
                            classes.active,
                            "font-16",
                          ].join(" ")
                        : [
                            classes.threeRowRightBottomCheckboxesItem,
                            "font-16",
                          ].join(" ")
                    }
                    onClick={() => {
                      checkArray(11, "other");
                    }}
                  >
                    other
                  </div>
                </div>
              </div>
              <div className={[classes.threeRowRightBtn, ""].join(" ")}>
                <button className="font-16">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seven;
